<template>
  <div class="duplicatesBox">
    <header class="headerBox">
      <span class="rulesBtn" @click="showPop(1)">{{ $t('budang_rule_button') }}</span>
    </header>
    <section class="sectionBox" v-if="showAct">
      <div class="timeBox">
        <!-- 距离活动开始 -->
        <p class="actTime" v-if="actInfo.timeState == 0">{{ $t('budang_beforestart') }}</p>
        <!-- 距离活动结束 -->
        <p class="actTime" v-if="actInfo.timeState == 1">{{ $t('budang_inprogress') }}</p>
        <!-- 活动已结束 -->
        <p class="actTime" v-if="actInfo.timeState == 2" style="opacity: 0.5;">{{ $t('budang_ended') }}</p>
        <div class="downTimeBox">
          <div>
            <div>
              <span>{{ days[0] }}</span>
              <span>{{ days[1] }}</span>
            </div>
            <p>{{ $t('budang_days') }}</p>
          </div>
          <div>
            <div>
              <span>{{ hours[0] }}</span>
              <span>{{ hours[1] }}</span>
            </div>
            <p>{{ $t('budang_hours') }}</p>
          </div>
          <div>
            <div>
              <span>{{ minutes[0] }}</span>
              <span>{{ minutes[1] }}</span>
            </div>
            <p>{{ $t('budang_minutes ') }}</p>
          </div>
          <div>
            <div>
              <span>{{ seconds[0] }}</span>
              <span>{{ seconds[1] }}</span>
            </div>
            <p>{{ $t('budang_seconds ') }}</p>
          </div>
        </div>
        <p class="gradeBox" v-if="actInfo.state == 1 && actInfo.hadLv > 0">{{  lvList[actInfo.hadLv - 1].name}}</p>
        <p class="gradeBox" v-else>{{ lvList[actLv].name }}</p>
        <div class="delayOpen"> 
          <img v-if="openStatus == 0" :src="getImageSrc()" alt="">   
          <img v-else :src="getImageSrc()" alt="">   
         <div class="treasureBox" v-if="actInfo.state == 1">
            <div class="openStatus" v-for="(item,index) in actInfo.rewards ? actInfo.rewards : lvList[actLv].reward1" :key="index">
                <img :src="item.gurl" alt="">
                <p>{{ item.gname }}</p>
              </div>
          </div>  
               
         </div> 
         <template v-if="actInfo.lv == 0">
          <div class="openBox openedBox">
            {{ $t('budang_open ') }}
          </div>
         </template>
         <template v-else>
          <div class="openBox openedBox" v-if="actInfo.state == 1 || actInfo.progress < lvList[actLv].min">
            {{ $t('budang_open ') }}
          </div>
          <div class="openBox" :class="openStatus == 1?'openedBox':''" @click="openGift" v-else>
            {{ $t('budang_open ') }}
          </div>
         </template>
        
        <p class="openTips">{{ $t('budang_open_once ') }}</p>
        <div class="userInfoBox">
          <div class="userInfo">
            <span>{{ $t('budang_myagency ') }}</span>
            <span>{{ actInfo.gname?actInfo.gname:'--' }}</span>
          </div>
          <div class="userInfo">
            <span>{{ $t('budang_mytarget ') }}</span>
            <span>{{ actInfo.progress | formatNumber }}</span> 
          </div>
          <div class="userInfoTips" v-if="actLv < lvList.length">{{ $t('budang_distanceaway ') }} {{ (actInfo.lv == 0 ? lvList[actLv].min - actInfo.progress :lvList[actLv].max - actInfo.progress) | formatNumber }} {{ $t('budang_distanceaway_2 ') }} {{ lvList[actInfo.lv].name }}</div>
          <div class="userInfoTips" v-else> {{ $t('budang_highestlevel ') }}</div>
          <div class="progressBox">
            <div class="progressBg">
              <div class="progress" v-if="actInfo.progress < lvList[actLv].max" :style="`width:${ (actInfo.progress / lvList[actLv].max) * 100 }%`"></div>
              <div class="progress" v-else style="width:100%"></div>
              <p class="progressNum" v-if="actInfo.progress < lvList[actLv].max" :style="`${langs == 'ar' ? 'right' : 'left'}:${ ((actInfo.progress / lvList[actLv].max) < 0.02 ? 0.02 : (actInfo.progress / lvList[actLv].max))  * 100 }%`">
                <i></i>
                <!-- <span>{{ actInfo.progress | formatNumber }}</span> -->
              </p>
            </div>
            <div class="levelBox">
              <span>{{ actLv == 0 && actInfo.lv == 0 ? '' : lvList[actLv].name  }}</span>
              <span v-if="actInfo.lv < lvList.length">{{ lvList[actInfo.lv].name }}</span>
              <span v-else>MAX</span>
            </div>
          </div>
        </div>
        <div class="levelGiftBox">
          <div class="swiper-container2">
            <header class="swiper-wrapper">
              <span class="swiper-slide" v-for="(item,index) in lvList" :key="index">{{ item.name }}</span>
            </header>
          </div>
          <div class="giftShow swiper-container">
            <span class="leftBtn swiper-button-prev"></span>
            <span class="rightBtn swiper-button-next"></span>
            <!-- <span class="leftBtn swiper-button-prev" @click="prevSwiper"></span>
            <span class="rightBtn swiper-button-next" @click="nextSwiper"></span> -->
            <ul class="swiper-wrapper">
              <li class="giftItem swiper-slide" v-for="(item,index) in lvList" :key="index">
                 <header>{{ $t('budang_rewardname ') }}</header>
                 <div class="giftInfo">
                    <div v-for="g in item.reward1" :key="g.gurl">
                      <img :src="g.gurl" alt="">
                      <p>{{ g.gname }}</p>
                    </div>
                 </div>
                 <div class="giftInfo">
                  <div v-for="g in item.reward2" :key="g.gurl">
                      <img :src="g.gurl" alt="">
                      <p>{{ g.gname }}</p>
                    </div>
                 </div>
              </li>
            </ul>
            
          </div>
        </div>
        <div class="footerText">{{ $t('budang_reward_rule') }}</div>
      </div>
    </section>
    <!-- 规则 -->
    <div class="popBox" v-if="popFlag>0">
      <div class="popContent" v-if="popFlag == 1">
        <div class="closeBtn" @click="showPop(0 )"></div>
        <div class="ruleBodyPar">
          <ul class="ruleBody">
            <li>{{ $t('budang_rule_row1_202502 ') }}</li>
            <li>{{ $t('budang_rule_row2 ') }}</li>
            <li>{{ $t('budang_rule_row3 ') }}</li>
            <li>{{ $t('budang_rule_row4 ') }}</li>
          </ul>
        </div>
        
      </div>
    </div>
  </div>
 
</template>

<script>
import { Toast } from "vant";
import "swiper/css/swiper.css"; //引入swiper样式
import Swiper from "swiper"; //引入swiper
export default {
  name: "duplicates",
  data() {
    return{
      showAct:false,
      // 规则弹窗
      popFlag: 0,
      // 指定滑块默认展示
      actSwiperNum:1,
      // swiper滑块
      swiperObj:null,
      swiperObj2:null,
      // 滑块总数量
      swiperLength: 10,
      days:[0,0],
      hours:[0,0],
      minutes:[0,0],
      seconds:[0,0],
      // 活动信息
      actInfo:{
        lv:''
      },
      // 等级礼物
      lvList:[
        {name:'',max:''}
      ],
      actLv:1,
      // 是否开启宝箱
      openStatus:0,
      url:'../../assets/duplicates_v4/c2End.webp'
    }
  },
  filters:{
    formatNumber(num) {
      if (num > 1000000) {
          return Math.trunc((num / 1000000) * 10) / 10 + 'M';
      } else if (num >= 1000) {
          return Math.trunc((num / 1000) * 10) / 10 + 'K';
      } else {
          return num.toString ();
      }
    },
  },
  created() { 
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo;
    window["getUserInfo"] = (data) => {
      this.getUserInfo(data);
    };

    // this.getUserInfo() 
  },
  mounted() {
    document.addEventListener('touchstart', function(event) {
        if (event.touches.length > 1) {
            event.preventDefault();
        }
    });
    document.addEventListener('gesturestart', function(event) {
        event.preventDefault();
    });
  },
  methods: {
    startSwiper2(){
      this.swiperObj2 = new Swiper('.swiper-container2',{
        slidesPerView: 6.8,
        spaceBetween: 5,
        freeMode: true,
        // loop: true,
        initialSlide:this.actSwiperNum, //默认展示滑块
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        allowTouchMove:false,//禁止手动拖拽
        // 中间对齐滑块
        centeredSlides: true,
        // 导航按钮
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // },
      })

    },
    startSwiper(){
      let that = this;
      this.swiperObj = new Swiper('.swiper-container', {
            effect: 'coverflow',
            centeredSlides: true,
            spaceBetween: '18%',
            slidesPerView: 'auto',
            initialSlide:this.actSwiperNum, //默认展示滑块
            // loop: true,
            allowTouchMove:true,//可以手动拖拽
            // autoplay: {
            //     disableOnInteraction: false,
            // },
            //   loopedSlides: 2,
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 350,
                modifier: 1,
                slideShadows: false,
            },
            // 观察滑块进度，以便应用过渡效果
            watchSlidesProgress: true,
            // 导航按钮
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            on:{
              slideChange(swiper,event){
                // console.log(that.swiperObj.activeIndex,'11111')
                that.swiperObj2.slideToLoop(this.activeIndex, 500, true);
              },
              // slidePrevTransitionStart: function(swiper){
              //   that.swiperObj2.slideToLoop(that.swiperObj.realIndex, 500, true);
              // },
              // slideNextTransitionStart: function(swiper){
              //   that.swiperObj2.slideToLoop(that.swiperObj.realIndex, 500, true);
              // },
            },
        });
    },
    getImageSrc() {
      const imageName = this.lvList[this.actLv].name.toLowerCase().trim();
      return require(`../../assets/duplicates_v4/${imageName}${this.actInfo.state == 1 ? 'End' : ''}.png`);
    },
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0,2)
      this.langs = this.userInfo.lang.substring(0,2)
      let lang = this.userInfo.lang
      this.headers = {
          fid:  this.userInfo.fid,
          os:  this.userInfo.os || 1,
          lang: this.userInfo.lang, 
          version:this.userInfo.version,
          deviceid:this.userInfo.deviceId,
        }
  //  this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOemt6TWpJNCIsInh5eiI6IlEwWkRRakJCTURBdFJEYzJOaTAwTURZMkxUaEJRamd0UkVaR01rVXhSRFpCUWpjMSIsImV4cCI6MTk5MjkzODIyNn0.-8-fxCL-8HULEkCIUdI9AaLZEBYdDwi9ZHC3cjhxa5Y';
  //     this.uid = '793228'; 
  //     this.headers = {
  //         fid: '1001',
  //         os: 1,
  //         lang: 'zh-CN',
  //         version: '3.0',
  //         deviceid: 'CFCB0A00-D766-4066-8AB8-DFF2E1D6AB75',
  //     }
  //     let langs = 'en'
  //     this.langs = langs
  //     let lang = 'zh_CN'
      document.title = this.$t('budang_title')
      if (langs == "ar") {
          this.$i18n.locale = "ar";
      } else {
          this.$i18n.locale = "en";
      }
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      
      this.getActInfo();
    },
    openGift(){
      this.openStatus =  1;
      clearTimeout(this.timer)
      // this.timer = setTimeout(() => {
        // 获取活动时间
        let personLeveldatas = {
          option: "get",
          host:
            this.$serviceIpJava +
            "api/code/budang/receive?token=" +
            this.token +
            "&uid=" +
            this.uid,
          data: "",
          lang: this.langs,
          header: this.headers,
        };
        this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
          if (data.code == 200) {
              this.actInfo.state = 1;
          }else{
            this.openStatus = 0;
            Toast(data.message);
          }
        });
      // },1300)
    },
    // 获取活动信息
    getActInfo(){
      // 获取活动时间
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/code/budang/info?token=" +
          this.token +
          "&uid=" +
          this.uid,
        data: "",
        lang: this.langs,
        header: this.headers,
      };
      this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
        if (data.code == 200) {
            this.actInfo = data.data;
            this.lvList = data.data.lvList;
            if(data.data.lv == 18){
              this.actLv = 17;
            }else if(data.data.lv == 1 || data.data.lv == 0){
              this.actLv = 0;
            }else{
              this.actLv = data.data.lv - 1;
            }
            this.actSwiperNum = this.actLv;
            this.openStatus = this.actInfo.state;
            // 活动未开始
            if(this.actInfo.timeState == 0){
              this.finalsDownTime(this.actInfo.startTime)
            }else if(this.actInfo.timeState == 1){  //活动已开始
              this.finalsDownTime(this.actInfo.endTime)
            }
            this.showAct = true;
            this.$nextTick(()=>{
              this.startSwiper2()
              this.startSwiper()
            })
        }else{
          Toast(data.message);
        }
      });
    },
    // 倒计时
    finalsDownTime(endTime) {
      this.timer = setInterval( ()=> {
        const now = new Date().getTime();
        const distance = endTime - now;
        if (distance < 0) {
          clearInterval(this.timer);
          return;
        }
        const days = this.zeroFill(Math.floor(distance / (1000 * 60 * 60 * 24)));
        const hours = this.zeroFill(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        const minutes = this.zeroFill(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        const seconds = this.zeroFill(Math.floor((distance % (1000 * 60)) / 1000));
        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
      }, 1000);
    },
    nextSwiper(){
      // this.swiperObj.slideNext()
      // this.swiperObj2.slideNext()
    },
    prevSwiper(){
      // this.swiperObj.slidePrev()
      // this.swiperObj2.slidePrev()
    },
    showPop(type){
      this.popFlag = type;
      if(type){
        this.stopScroll()
      }else{
        this.canScroll();
      }
    },
    zeroFill(num){
      if(num < 10){
        return [0,num]
      }else{
        return [num.toString().slice(0,1),num.toString().slice(1)]
      }
    },
  }
};
</script>

<style scoped>
@import './index.css';
</style>
